@import '../../../../assets/styles/vars';

.call-form {
  background-color: #FED969;
  padding: 40px 11px 40px 12px;
  text-align: center;

  @media(min-width: $desktop-width) {
    margin-right: 24px;
    width: 50%;
    border-radius: 24px;
    padding: 48px 48px 71px;
    text-align: left;
  }

  &__text-wrapper {
    margin-bottom: 24px;
    max-width: 290px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
      margin: 0 auto 24px;
    }

    @media(min-width: $desktop-width) {
      margin: 0 0 24px;
    }
  }

  &__image {
    @media(min-width: $desktop-width) {
      display: none;
    }

    &--small {
      display: none;

      @media(min-width: $desktop-width) {
        height: 72px;
        display: inline;
      }
    }
  }

  &__header {
    max-width: 270px;
    margin: 24px 0 8px;
    line-height: 32px;
    text-align: left;

    @media(min-width: $tablet-width) {
      text-align: center;
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      text-align: left;
      margin: 0 0 24px;
      font-size: 48px;
      line-height: 72px;
    }
  }

  &__text {
    line-height: 24px;
    max-width: 351px;
    margin-bottom: 24px;
    text-align: left;

    @media(min-width: $tablet-width) {
      text-align: center;
      margin: 0 auto 24px;
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      text-align: left;
      margin: 0 0 24px;
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
    }
  }

  &__button {
    margin-top: 16px;

    @media(min-width: $desktop-width) {
      margin-top: 21px;
    }
  }
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  height: 100%;
  background-color: #F5F7F9;
  font-family: 'Roboto', sans-serif;
  color: #202331;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

*, :after, :before {
  box-sizing: border-box;
}

h1, h2 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

@import '../../../../assets/styles/vars';

.description {
  text-align: center;
  padding: 0 11px 13px 12px;

  .pointer {
    cursor: pointer;
  }

  .text-link {
    color: #0570E3;
  }

  @media(min-width: $desktop-width) {
    padding: 88px 90px 128px;
  }

  &__header {
    text-align: left;
    margin-bottom: 32px;
    max-width: 312px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
      margin: 0 auto 32px;
      text-align: center;
    }

    @media(min-width: $desktop-width) {
      margin: 0 0 25px;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $tablet-width) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__list-line {
    display: none;

    @media(min-width: $desktop-width) {
      display: block;
      margin: 0 16px;
      width: 156px;
      height: 2px;
      background-color: #A4C7D0;
    }
  }

  &__item {
    margin-bottom: 47px;
    width: 200px;

    @media(min-width: $desktop-width) {
      margin-bottom: 0;
      width: 240px;
    }
  }

  &__image-wrapper {
    margin-bottom: 8px;

    @media(min-width: $desktop-width) {
      margin-bottom: 0;
    }
  }

  &__image {
    height: 140px;
  }

  &__item-text {
    margin-top: 10px;
    line-height: 24px;
    min-height: 48px;

    @media(min-width: $desktop-width) {
      line-height: 32px;
      min-height: 64px;
    }
  }
}

@import '../../assets/styles/vars';

.applications {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(292.37deg, #FF5375 0%, #478AFF 100%);
  padding: 48px 68px;

  .pointer {
    cursor: pointer;
  }

  @media(min-width: $desktop-width) {
    padding: 120px 0 100px 170px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $desktop-width) {
      align-items: start;
    }

    &-logo {
      width: 96px;
      height: 96px;

      @media(min-width: $desktop-width) {
        width: 156px;
        height: 156px;
      }
    }
    &-title {
      margin-top: 50px;
      font-size: 32px;
      line-height: 40px;
      color: #FFFFFF;
      text-align: center;

      @media(min-width: $desktop-width) {
        width: 70%;
        text-align: left;
        font-size: 64px;
        line-height: 80px;
      }
    }
    &-download {
      margin-top: 65px;
      display: flex;
      flex-direction: column;

      &-ios {
        margin-top: 10px;
      }

      @media(min-width: $desktop-width) {
        flex-direction: row;
        &-ios {
          margin-left: 32px;
          margin-top: 0;
        }
      }
    }
  }

  &__wrapper {
    @media(min-width: $desktop-width) {
      margin: 50px 60px 127px;
      display: flex;
    }
  }
}

.main-large {
  font-weight: bold;
  letter-spacing: 1.2px;
  font-size: 32px;
  line-height: 40px;

  @media(min-width: $desktop-width) {
    font-size: 64px;
    line-height: 80px;
  }
}

.header-normal {
  font-weight: bold;
  letter-spacing: 1.2px;
  font-size: 32px;
  line-height: 40px;

  @media(min-width: $desktop-width) {
    font-size: 48px;
    line-height: 72px;
  }
}

.header-small {
  font-weight: bold;
  letter-spacing: 1.2px;
  font-size: 24px;
  line-height: 40px;

  @media(min-width: $desktop-width) {
    font-size: 32px;
  }
}

.text-large {
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 16px;
  line-height: 24px;

  @media(min-width: $desktop-width) {
    font-size: 24px;
    line-height: 56px;
  }
}

.text-normal {
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 14px;
  line-height: 18px;

  @media(min-width: $desktop-width) {
    font-size: 18px;
    line-height: 24px;
  }
}

.receipt-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

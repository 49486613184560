@import '../../assets/styles/vars';

.home {
  margin: 0 auto;

  &__header-wrapper {
    width: 100%;
    background: linear-gradient(292.37deg, #FF5375 0%, #478AFF 100%);
  }

  &__wrapper {
    @media(min-width: $desktop-width) {
      margin: 50px 0 127px;
      display: flex;
    }
  }
}

.call-modal {
  padding: 40px 26px 32px 27px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  background-color: #F6F9FF;
  border-radius: 4px;
  border: 1px solid #E6EEF4;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

  &__wrapper {
    margin: 0 auto;
    width: 240px;
  }

  &__header {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__button {
    margin: 0 6px 0 5px;
    box-shadow: 0 8px 16px rgba(95, 98, 115, 0.32);
  }

  &__button-close {
    width: 20px;
    height: 20px;
    background-color: transparent;
    position: absolute;
    top: 18px;
    right: 16px;

    &:hover {
      border: none;
    }
  }
}

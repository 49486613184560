@import '../../../../../../assets/styles/vars';

.organization {
  background-color: #DCEEF7;
  display: flex;
  flex-direction: column;

  @media(min-width: $tablet-width) {
    align-items: center;
  }

  @media(min-width: $desktop-width) {
    border-radius: 24px;
    margin: 0 0px 24px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__text-wrapper {
    max-width: 348px;
    padding: 40px 0 0 16px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      padding: 64px 0 99px 112px;
      width: 50%;
    }
  }

  &__header {
    margin-bottom: 16px;
    max-width: 299px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      margin-bottom: 24px;
    }
  }

  &__list {
    padding-left: 18px;

    @media(min-width: $desktop-width) {
      padding-left: 20px;
    }
  }

  &__item {
    line-height: 24px;

    @media(min-width: $desktop-width) {
      line-height: 32px;
    }
  }

  &__image {
    width: 100%;

    @media(min-width: $desktop-width) {
      width: 50%;
    }
  }
}

@import '../../assets/styles/vars';

.button {
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $button-color;
  background-color: $second-button-color;
  border-radius: 4px;


  @media(min-width: $desktop-width) {
    font-size: 24px;
    line-height: 32px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border: 2px solid $button-color;
  }

  &:disabled {
    background-color: $disabled-color;
    border: none;
    cursor: default;
  }

  &--border {
    border: 2px solid $button-color;
    background-color: transparent;

    &:hover {
      background-color: $button-color;
      color: $second-button-color;
    }

    &:disabled {
      background-color:  $disabled-color;
      border: 2px solid $button-color;
      color: $button-color;
    }
  }

  &--blue {
    background-color: $button-color;
    color: $second-button-color;

    &:hover {
      border: none;
      background-color: $second-button-color;
      color: $button-color;
    }

    &:disabled {
      background-color:  $disabled-color;
      color: $second-button-color;
    }
  }
}

.input {
  width: 100%;
  height: 64px;
  border: 2px solid #5F6273;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 16px 18px;
  letter-spacing: 0.2px;
  color: #5F6273;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &--invalid {
    border: 2px solid red;
  }
}

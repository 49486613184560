@import '../../../../assets/styles/vars';

.about {
  position: relative;
  padding: 32px 11px 32px 12px;

  @media(min-width: $desktop-width) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 62px 0 36px 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @media(min-width: $tablet-width) {
      align-items: center;
    }

    @media(min-width: $desktop-width) {
      display: block;
      width: 570px;
    }
  }

  &__header {
    max-width: 310px;
    height: 144px;
    margin-bottom: 227px;
    line-height: 48px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
      text-align: center;
    }

    @media(min-width: $desktop-width) {
      text-align: left;
      line-height: 72px;
      height: auto;
      width: auto;
      margin: 18px 0 24px;
    }
  }

  &__list-wrapper {
    text-align: left;
  }

  &__list-header {
    margin-bottom: 16px;

    @media(min-width: $desktop-width) {
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  &__list {
    padding-left: 18px;

    @media(min-width: $desktop-width) {
      padding-left: 20px;
    }
  }

  &__item {
    line-height: 24px;

    @media(min-width: $desktop-width) {
      line-height: 32px;
    }
  }

  &__logo-wrapper {
    width: 100%;
    text-align: center;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 163px;

    @media(min-width: $desktop-width) {
      width: 556px;
      position: static;
      margin-left: 18px;
    }
  }

  &__logo {
    height: 272.2px;

    @media(min-width: $desktop-width) {
      height: auto;
      width: 556px;
    }
  }
}

@import '../../../../assets/styles/vars';

.main {
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  text-align: center;
  padding: 48px 8px 32px 9px;
  color: #fff;

  @media(min-width: $desktop-width) {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: left;
  }

  &__link:hover {
    text-decoration: none;
  }

  &__button {
    width: calc(100% - 23px);
    height: 56px;
    position: absolute;
    bottom: 32px;
    font-size: 18px;

    @media(min-width: $desktop-width) {
      width: 480px;
      height: 64px;
      position: static;
      font-size: 24px;
    }
  }

  &__logo {
    width: 96px;
    margin-bottom: 48px;

    @media(min-width: $desktop-width) {
      width: 156px;
      margin-bottom: 120px;
    }
  }

  &__header {
    max-width: 296px;
    margin: 0 auto 4px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      margin: 0 0 48px;
    }
  }

  &__rocket {
    height: 40px;

    @media(min-width: $desktop-width) {
      height: 80px;
    }
  }

  &__button-arrow {
    margin-left: 13px;
    display: block;
  }

  &__text {
    @media(min-width: $desktop-width) {
      display: none;
    }
  }

  &__image-wrapper {
    margin-bottom: 66px;
    width: 100%;

    @media(min-width: $desktop-width) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__image {
    width: 100%;
  }
}

@import '../../../../assets/styles/vars';

.facts {
  padding: 40px 11px 40px 12px;

  @media(min-width: $desktop-width) {
    padding: 48px 35px 35px 48px;
    width: 50%;
  }

  &__header {
    margin-bottom: 16px;

    @media(min-width: $desktop-width) {
      margin-bottom: 9px;
    }
  }

  &__text {
    margin-bottom: 16px;
    max-width: 295px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      line-height: 40px;
      font-weight: bold;
      width: auto;
    }
  }

  &__list-header {
    font-weight: bold;
    line-height: 32px;
  }

  &__list {
    padding-left: 20px;
    max-width: 341px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
    }
  }

  &__list-text {
    font-weight: bold;
    line-height: 32px;
    text-decoration: underline;
  }
}

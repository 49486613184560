@import '../../../../../../assets/styles/vars';

.reporting {
  background-color: rgba(39, 174, 96, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(min-width: $tablet-width) {
    align-items: center;
  }

  @media(min-width: $desktop-width) {
    margin-right: 24px;
    width: 50%;
    border-radius: 24px;
  }

  &__text-wrapper {
    max-width: 360px;
    padding: 40px 0 0 16px;

    @media(min-width: $tablet-width) {
      max-width: 100%;
    }

    @media(min-width: $desktop-width) {
      padding: 64px 0 0;
      width: 440px;
    }
  }

  &__header {
    margin-bottom: 16px;

    @media(min-width: $desktop-width) {
      margin-bottom: 24px;
    }
  }

  &__list {
    padding-left: 18px;

    @media(min-width: $desktop-width) {
      padding-left: 20px;
    }
  }

  &__item {
    line-height: 24px;

    @media(min-width: $desktop-width) {
      line-height: 32px;
    }
  }

  &__image {
    width: 100%;
  }
}

.receipt {
  font-family: 'IBMPlexMono', sans-serif;
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  padding: 24px 11px 32px 12px;
  min-height: 100vh;
  text-align: center;
  max-width: 375px;
  margin: 0 auto;

  &__wrapper {
    border-bottom: 1px dashed #202331;
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 8px;
  }

  &__text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: left;
  }

  &__price {
    white-space: nowrap;
  }

  &__price-wrapper {
    text-align: end;
  }

  &__qrcode-wrapper {
    margin-top: 16px;
  }
}
